<template>
  <div class="dashboard m-2 p-3 pb-5">
    <div class="row mb-4">
      <div class="col-12">
        <div class="stats-box larkin-enclosure-box px-5">
          <div class="row">
            <div class="col stats-col">Pending</div>
            <div class="col stats-col"><i class="icon icon-arrow-right1"></i></div>
            <div class="col stats-col">Approved</div>
            <div class="col stats-col"><i class="icon icon-arrow-right1"></i></div>
            <div class="col col-3 stats-col">Active</div>
            <div class="col stats-col"><i class="icon icon-arrow-right1"></i></div>
            <div class="col stats-col text-nowrap">Closed<br /><span class="text-small">({{showClosedLeaveDateRange()}})</span></div>
          </div>
          <div class="row">
            <div class="col stats-col"><div class="larkin-enclosure-box" v-b-popover.hover.bottom="'Leaves that have been requested but not yet approved.'" v-on:click="toggleStatistic('total.pending')" :class="{ selected: isSelected('total.pending')}">{{getLengthFromResultPath('total.pending')}}</div></div>
            <div class="col stats-col"></div>
            <div class="col stats-col"><div class="larkin-enclosure-box" v-b-popover.hover.bottom="'Leaves that have been approved but not yet begun.'" v-on:click="toggleStatistic('total.notStarted')" :class="{ selected: isSelected('total.notStarted')}">{{getLengthFromResultPath('total.notStarted')}}</div></div>
            <div class="col stats-col"></div>
            <div class="col col-3 stats-col">
              <div class="row">
                <div class="col-12 p-0"><div class="larkin-enclosure-box w-100" v-b-popover.hover.bottom="'Leaves that are approved and currently active. This may include intermittent activity as well as block leaves.'" v-on:click="toggleStatistic('total.active')" :class="{ selected: isSelected('total.active')}">{{getLengthFromResultPath('total.active')}}</div></div>
              </div>
              <div class="row larkin-sub-row">
                <div class="col p-0 pr-1">
                  <div class="larkin-enclosure-box w-100" v-b-popover.hover.top="'Employees who are actively on leave as of today. This may include an intermittent occurrence or a block leave.'" v-on:click="toggleStatistic('total.activeOnLeave')" :class="{ selected: isSelected('total.activeOnLeave')}">{{getLengthFromResultPath('total.activeOnLeave')}}</div>
                  <p class="pt-1">On Leave</p>
                </div>
<!--                <div class="col p-0 pr-1">-->
<!--                  <div class="larkin-enclosure-box w-100" v-on:click="toggleStatistic('total.active')" :class="{ selected: isSelected('total.active')}">{{getLengthFromResultPath('total.active')}}</div>-->
<!--                  <p class="pt-1">Label</p>-->
<!--                </div>-->
                <div class="col p-0">
                  <div class="larkin-enclosure-box w-100" v-b-popover.hover.top="'Employees who have confirmed their return to work as of today.'" v-on:click="toggleStatistic('total.activeConfirmRTW')" :class="{ selected: isSelected('total.activeConfirmRTW')}">{{getLengthFromResultPath('total.activeConfirmRTW')}}</div>
                  <p class="pt-1">Confirm RTW</p>
                </div>
              </div>
            </div>
            <div class="col stats-col"></div>
            <div class="col stats-col"><div class="larkin-enclosure-box" v-b-popover.hover.bottom="'Leaves closed within the prior four weeks. This includes denied and cancelled leaves as well.'" v-on:click="toggleStatistic('total.limitedClosed')" :class="{ selected: isSelected('total.limitedClosed')}">{{getLengthFromResultPath('total.limitedClosed')}}</div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="larkin-enclosure-box">
      <table ref="leaveTable" v-if="filteredLeaves.length" role="table" aria-busy="false" aria-colcount="8" class="table b-table leave-table table-row-click table-hover">
        <thead role="rowgroup">
        <tr role="row">
          <th role="columnheader" scope="col" :aria-sort="this.sort.lastName" aria-colindex="1" class="clickable" @click="sortEmployeeStringBy('lastName',true)">Last Name</th>
          <th role="columnheader" scope="col" :aria-sort="this.sort.firstName" aria-colindex="2" class="clickable" @click="sortEmployeeStringBy('firstName',true)">First Name</th>
          <th role="columnheader" scope="col" aria-colindex="3" class="">Type</th>
          <th role="columnheader" scope="col" aria-colindex="4" class="">Status</th>
          <th role="columnheader" scope="col" aria-colindex="5" class="leave-col-from">From</th>
          <th role="columnheader" scope="col" aria-colindex="6" class="leave-col-through">Through</th>
          <th role="columnheader" scope="col" aria-colindex="7" class="leave-col-weeks">Weeks Used</th>
          <th role="columnheader" scope="col" aria-colindex="8" class="leave-col-protection">Protections</th>
          <th role="columnheader" scope="col" aria-colindex="9" class="">Administrator</th>
        </tr>
        </thead>
        <tbody role="rowgroup">
        <tr role="row" tabindex="0" v-for="leave in filteredLeaves" :key="leave.leaveId" @click="selectLeave(leave.employee.masterEmployeeId, leave.leaveId)">
          <td aria-colindex="1" role="cell">{{leave.employee.lastName}}</td>
          <td aria-colindex="2" role="cell">{{leave.employee.firstName}}</td>
          <td aria-colindex="3" role="cell">{{leave.leaveType}}</td>
          <td aria-colindex="4" role="cell">{{getStatusString(leave)}}</td>
          <td colspan="4" class="schedule-sub-table">
            <div v-for="detail in leave.details" class="d-inline-flex flex-row">
              <div class="leave-col-from">{{formatDate(detail.from)}}</div>
              <div class="leave-col-through">{{formatDate(detail.to)}}</div>
              <div class="leave-col-weeks">{{detail.workWeeks}}</div>
              <div class="leave-col-protection">{{getProtectionNames(detail.protections)}}</div>
            </div>
          </td>
          <td aria-colindex="9" role="cell" class="pg">{{leave.employee.administrator}}</td>
        </tr>
        </tbody>
      </table>
      <div v-else-if="!filteredLeaves"><h6>No Active Leaves found.</h6></div>
    </div>
  </div>
</template>

<script>
import ApiProperties from '../../../src/main/resources/application'
import dateMixin from '../mixins/dateMixin.js';
import leaveMixin from '../mixins/leaveMixin.js';
import get from 'just-safe-get';

export default {
  name: 'dashboard',
  components: {},
  mixins: [dateMixin, leaveMixin],
  data: function() {
    return {
      sort: {
        'lastName': 'ascending',
        'firstName': null
      },
      result: {
        pending: 0,
        recent: {}, // Leaving this here, but is not getting results for now
        total: {},
        leaves: []
      },
      filter: {
        selected: null,
      },
      filteredLeaves: [],
      errors: []
    }
  },
  methods: {
    showClosedLeaveDateRange: function() {
      const startDate = get(this.result, "total.closedLeaveFromDate")
      if (startDate) {
        return `${this.formatDate(startDate)} - ${this.formatDate(new Date())}`
      }

      return "4 weeks ago"
    },

    clearPreviousSort: function(sortBy) {
      if (sortBy==='firstName') {
        this.sort.lastName = null
      } else if (sortBy==='lastName') {
        this.sort.firstName = null
      }
    },

    resortLeaves: function() {
      if (this.sort.firstName !== null) {
        this.sortEmployeeStringBy("firstName", false)
      } else if (this.sort.lastName !== null) {
        this.sortEmployeeStringBy("lastName", false)
      }
    },

    sortEmployeeStringBy: function(sortBy, toggleSort) {
      function compare(a, b) {
        if ( a.employee[sortBy].toLowerCase() < b.employee[sortBy].toLowerCase() ){
          return -1;
        }
        if ( a.employee[sortBy].toLowerCase() > b.employee[sortBy].toLowerCase() ){
          return 1;
        }
        return 0;
      }

      this.clearPreviousSort(sortBy)

      if (toggleSort) {
        this.sort[sortBy] = (this.sort[sortBy]==='ascending') ? 'descending' : 'ascending';
      }

      (this.sort[sortBy]==="ascending") ? this.filteredLeaves.sort(compare) : this.filteredLeaves.sort(compare).reverse()
    },

    getLengthFromResultPath: function(path) {
      return get(this.result, path+'.length') || 0
    },

    selectLeave(employeeId, leaveId) {
      this.$router.push({ name: 'dashboardLeaveDetail', params: { employeeId, leaveId } })
    },

    toggleStatistic: function(path) {
      if (this.filter.selected===path) {
        this.filter.selected=null
        this.filteredLeaves = Object.assign([], this.result.leaves)
        this.resortLeaves()
      } else {
        const leaveIds = get(this.result, path)
        this.filteredLeaves = Object.assign([], this.result.leaves.filter(leave => leaveIds.includes(leave.leaveId)))
        this.resortLeaves()
        this.filter.selected = path;
      }
    },

    isSelected: function(path) {
      return (path===this.filter.selected)
    },

    getLeavesAndLeaveStatistics: function() {
      this.$http.get(ApiProperties.api.employerDashboard).then(response => {
        this.result = response.data
        this.filteredLeaves = Object.assign([], this.result.leaves)
        this.sortEmployeeStringBy("lastName", false)
      }).catch(e => {
        this.errors.push(e);
      })
    }
  },

  mounted() {
    this.getLeavesAndLeaveStatistics();
  }
}
</script>
<style lang="scss">
  @import '../scss/leave-table.scss';

  .dashboard {
    color: #2c2e30;
    font-size: .8em;
    min-width: 1020px;

    .stats-box {
      height: 250px;
      padding-bottom: 10px;
    }

    .clickable {
      cursor: pointer;
    }

    .row {
      margin-bottom: 5px;

      .text-small {
        font-size: .8em;
      }

      .stats-label-col {
        padding: 0;
        text-align: left;
      }
      .stats-col {
        font-size: 14px;
        padding: 0;
        text-align: center;

        .larkin-enclosure-box {
          padding: 0;
          font-weight: 900;
          font-size: 2em;
          line-height: 80px;
          margin: 0 auto;
          width: 80px;
          height:80px;
          background: rgba(226,226,226,0.3);
          cursor: pointer;

          &.selected {
            color: #fff;
            background: #2c2e30;
          }
        }

        .larkin-sub-row {
          .larkin-enclosure-box {
            height:50px;
            line-height: 50px;
            font-size: 1.7em;
          }
        }

        i.icon {
          font-size: 27px;
          color: #d8d8d8;
        }
      }
    }
  }
</style>
